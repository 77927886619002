import React, { useMemo, useState, useEffect } from "react";

function ChampionTable({
  champions,
  teamName,
  reRoll,
  table,
  version,
  tableOptions,
  sortOption,
}) {
  const [base64Images, setBase64Images] = useState({});

  useEffect(() => {
    const loadImages = async () => {
      const newBase64Images = {};
      for (const champion of champions) {
        const imageUrl = `https://ddragon.leagueoflegends.com/cdn/${version}/img/champion/${champion.id}.png`;
        newBase64Images[champion.id] = await toDataURL(imageUrl);
      }
      setBase64Images(newBase64Images);
    };

    loadImages();
  }, [champions, version]);

  const sortedChampions = useMemo(() => {
    const championsCopy = [...champions];

    const getSortedChampions = () => {
      switch (sortOption) {
        case "alphabetical":
          return championsCopy.sort((a, b) => a.name.localeCompare(b.name));
        default:
          return champions;
      }
    };

    return getSortedChampions();
  }, [champions, sortOption]);

  if (!champions || champions.length === 0) {
    return null;
  }

  return (
    <table
      className={`${!tableOptions.rank ? "hide-rank" : ""} ${
        !tableOptions.winrate ? "hide-winrate" : ""
      } ${!tableOptions.tier ? "hide-tier" : ""}`}
    >
      <thead>
        <tr>
          <th
            className={`${teamName === "블루 팀" ? "blue-team" : "red-team"}`}
            colSpan="4"
          >
            {teamName}
          </th>
        </tr>
        <tr>
          <th className="champion">챔피언</th>
        </tr>
      </thead>
      <tbody>
        {sortedChampions.map((champion) => {
          const originalIndex = champions.findIndex(
            (ch) => ch.id === champion.id
          );

          return (
            <tr key={champion.id} onClick={() => reRoll(table, originalIndex)}>
              <td className="champion">
                <div className="champion-name">
                  <img src={base64Images[champion.id]} />
                  {champion.name}
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

const toDataURL = (url) => {
  return fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
    );
};

export default React.memo(ChampionTable);
