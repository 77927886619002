import React from "react";
import Modal from "react-modal";

function BanModal({
  isOpen,
  champions,
  version,
  bannedChampions,
  toggleBan,
  closeModal,
  setBannedChampions,
}) {
  const handleClearBans = () => {
    setBannedChampions([]);
    localStorage.removeItem("lolApp_bannedChampions");
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className="modal-overlay"
      ariaHideApp={false}
    >
      <div className="modal-wrapper">
        <div className="modal-content">
          {champions &&
            Object.values(champions.data)
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((champion) => {
                const isBanned = bannedChampions.includes(champion.id);
                return (
                  <div
                    key={champion.id}
                    className={`champion-item ${isBanned ? "banned" : ""}`}
                    onClick={() => toggleBan(champion.id)}
                  >
                    <img
                      src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/champion/${champion.id}.png`}
                      alt={champion.name}
                      className={isBanned ? "banned" : ""}
                    />
                    <span className={isBanned ? "banned" : ""}>
                      {champion.name}
                    </span>
                  </div>
                );
              })}
        </div>
        <div className="option-modal-buttons">
          <button onClick={handleClearBans} className="clear-button">
            모두 해제 ({bannedChampions.length})
          </button>
          <button onClick={closeModal} className="close-button">
            닫기
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default BanModal;
