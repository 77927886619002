import React, { useState, useEffect, useCallback, useRef } from "react";
import "./reset.css";
import "./App.css";
import Modal from "react-modal";
import ChampionTable from "./components/ChampionTable";
import BanModal from "./components/BanModal";
import OptionModal from "./components/OptionModal";
import html2canvas from "html2canvas";
import { shuffleArray } from "./utils/utils";

Modal.setAppElement("#root");

function App() {
  const captureRef = useRef();

  const copyToClipboard = async () => {
    const canvas = await html2canvas(captureRef.current, {
      foreignObjectRendering: true,
      allowTaint: true,
      useCORS: true,
      scale: 1,
    });

    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2 - 10;
    const captureWidth = 580; // 캡쳐할 너비
    const captureHeight = 700; // 캡쳐할 높이
    const startX = centerX - captureWidth / 2;
    const startY = centerY - captureHeight / 2;

    const croppedCanvas = document.createElement("canvas");
    croppedCanvas.width = captureWidth;
    croppedCanvas.height = captureHeight;

    croppedCanvas
      .getContext("2d")
      .drawImage(
        canvas,
        startX,
        startY,
        captureWidth,
        captureHeight,
        0,
        0,
        captureWidth,
        captureHeight
      );

    croppedCanvas.toBlob(async (blob) => {
      const item = new ClipboardItem({ "image/png": blob });
      await navigator.clipboard.write([item]);
      alert(
        "클립보드에 이미지가 복사되었습니다.\n이미지 붙여넣기(ctrl + v)로 사용하세요."
      );
    });
  };

  const [gameData, setGameData] = useState({
    championData: null,
    version: null,
  });

  // 랜덤으로 뽑힌 챔피언의 상태 관리
  const [randomChampions, setRandomChampions] = useState({
    table1: [],
    table2: [],
  });

  // 모달 상태 관리
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOptionModalOpen, setIsOptionModalOpen] = useState(false);

  // 밴된 챔피언 상태 관리
  const [bannedChampions, setBannedChampions] = useState(() => {
    const storedBannedChampions = localStorage.getItem(
      "lolApp_bannedChampions"
    );
    return storedBannedChampions ? JSON.parse(storedBannedChampions) : [];
  });

  // 다시 뽑기 횟수 상태 관리
  const [resetCount, setResetCount] = useState(0);

  // 챔피언 표시 개수 상태 관리
  const [displayCount, setDisplayCount] = useState(15);

  // 테이블 옵션 상태 관리
  const [tableOptions, setTableOptions] = useState(() => {
    const storedTableOptions = localStorage.getItem("lolApp_tableOptions");
    return storedTableOptions
      ? JSON.parse(storedTableOptions)
      : {
          rank: false,
          winrate: false,
          tier: true,
        };
  });

  // 정렬 옵션 상태 관리
  const [sortOption, setSortOption] = useState(() => {
    const storedSortOption = localStorage.getItem("lolApp_sortOption");
    return storedSortOption || "alphabetical";
  });

  // 데이터 로딩 완료 상태 관리
  const [dataLoaded, setDataLoaded] = useState(false);

  // 챔피언 랭킹 훅 사용
  // const { champions: aramRankings } = useChampionRanking();

  // 밴 상태 토글 핸들러
  const handleToggleBan = useCallback((championId) => {
    setBannedChampions((prevBans) =>
      prevBans.includes(championId)
        ? prevBans.filter((id) => id !== championId)
        : [...prevBans, championId]
    );
  }, []);

  // 챔피언들을 랜덤하게 뽑는 핸들러
  const resetRandomChampions = useCallback(() => {
    const champions = Object.values(gameData.championData.data).filter(
      (champion) => !bannedChampions.includes(champion.id)
    );
    const shuffled = shuffleArray(champions);
    setRandomChampions({
      table1: shuffled.slice(0, displayCount),
      table2: shuffled.slice(displayCount, displayCount * 2),
    });
    setResetCount((prevCount) => prevCount + 1);
  }, [gameData.championData, bannedChampions, displayCount]);

  // 특정 챔피언을 다시 랜덤하게 뽑는 핸들러
  const handleReRollChampion = useCallback(
    (table, index) => {
      const selectedChampion = randomChampions[table][index].name; // 리롤 전 챔피언 이름

      const isConfirmed = window.confirm(
        `"${selectedChampion}"을(를) 다시 랜덤하게 돌리시겠습니까?`
      );
      if (isConfirmed) {
        const currentTableChampions = [
          ...randomChampions.table1,
          ...randomChampions.table2,
        ].map((champ) => champ.id);

        const availableChampions = Object.values(
          gameData.championData.data
        ).filter(
          (champion) =>
            !bannedChampions.includes(champion.id) &&
            !currentTableChampions.includes(champion.id)
        );

        if (availableChampions.length === 0) {
          alert("사용 가능한 챔피언이 없습니다.");
          return;
        }

        const randomChampion =
          availableChampions[
            Math.floor(Math.random() * availableChampions.length)
          ];
        const updatedTable = [...randomChampions[table]];
        updatedTable[index] = randomChampion;
        setRandomChampions({
          ...randomChampions,
          [table]: updatedTable,
        });

        // 리롤 후 나온 챔피언에 대한 메시지를 표시
        alert(
          `"${selectedChampion}"을(를) 돌려서 "${randomChampion.name}"이(가) 나왔습니다.`
        );
      }
    },
    [randomChampions, gameData.championData, bannedChampions]
  );

  // 옵션 변경시 로컬스토리지 저장
  useEffect(() => {
    localStorage.setItem("lolApp_tableOptions", JSON.stringify(tableOptions));
    localStorage.setItem("lolApp_sortOption", sortOption);
  }, [tableOptions, sortOption]);

  // 챔피언 밴 변경시 로컬스토리지 저장
  useEffect(() => {
    localStorage.setItem(
      "lolApp_bannedChampions",
      JSON.stringify(bannedChampions)
    );
  }, [bannedChampions]);

  // 컴포넌트가 언마운트되었는지 확인하기 위한 참조 변수
  useEffect(() => {
    const isMounted = { value: true };

    const fetchData = async () => {
      try {
        const response1 = await fetch(
          "https://ddragon.leagueoflegends.com/realms/kr.json"
        );
        const data1 = await response1.json();
        const championVersion = data1.n.champion;
        const response2 = await fetch(
          `https://ddragon.leagueoflegends.com/cdn/${championVersion}/data/ko_KR/champion.json`
        );
        const data2 = await response2.json();

        // 마운트 상태에서만 상태 업데이트
        if (isMounted.value) {
          setGameData({
            championData: data2,
            version: championVersion,
          });
        }
      } catch (error) {
        if (isMounted.value) {
          console.error("Error fetching the data", error);
        }
      }
    };

    fetchData();

    // 컴포넌트가 언마운트될 때 참조 변수를 변경
    return () => {
      isMounted.value = false;
    };
  }, []);

  // 데이터 로딩이 완료되면 데이터 로딩 상태를 변경
  useEffect(() => {
    if (gameData.championData) {
      setDataLoaded(true);
      resetRandomChampions();
    }
  }, [gameData.championData]);

  return (
    dataLoaded && (
      <div className="App">
        {
          <>
            <div className="button-container">
              <button
                className="ban-button"
                onClick={() => setIsModalOpen(true)}
              >
                챔피언 밴 ({bannedChampions.length})
              </button>
              <button
                className="option-button"
                onClick={() => setIsOptionModalOpen(true)}
              >
                옵션
              </button>
              <button className="copy-button" onClick={copyToClipboard}>
                스크린샷
              </button>
            </div>

            <div className="container" ref={captureRef}>
              <ChampionTable
                champions={randomChampions.table1}
                teamName="블루 팀"
                reRoll={handleReRollChampion}
                table="table1"
                version={gameData.version}
                tableOptions={tableOptions}
                sortOption={sortOption}
              />
              <button onClick={resetRandomChampions}>
                다시 뽑기
                <span>({resetCount})</span>
              </button>
              <ChampionTable
                champions={randomChampions.table2}
                teamName="레드 팀"
                reRoll={handleReRollChampion}
                table="table2"
                version={gameData.version}
                tableOptions={tableOptions}
                sortOption={sortOption}
              />
              <BanModal
                isOpen={isModalOpen}
                champions={gameData.championData}
                version={gameData.version}
                bannedChampions={bannedChampions}
                setBannedChampions={setBannedChampions}
                toggleBan={handleToggleBan}
                closeModal={() => setIsModalOpen(false)}
              />
              <OptionModal
                isOpen={isOptionModalOpen}
                displayCount={displayCount}
                setDisplayCount={setDisplayCount}
                closeModal={() => setIsOptionModalOpen(false)}
                tableOptions={tableOptions}
                setTableOptions={setTableOptions}
                sortOption={sortOption}
                setSortOption={setSortOption}
                maxDisplayCount={Math.floor(
                  (Object.keys(gameData.championData.data).length -
                    bannedChampions.length) /
                    2
                )}
              />
            </div>

            <footer className="footer">
              패치 버전: {gameData.version.split(".").slice(0, 2).join(".")}
              <br />
              오류 및 문의사항: c99@kakao.com
            </footer>
          </>
        }
      </div>
    )
  );
}

export default App;
