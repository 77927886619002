import React, { useState, useEffect } from "react";
import Modal from "react-modal";

function OptionModal({
  isOpen,
  displayCount,
  setDisplayCount,
  sortOption,
  setSortOption,
  closeModal,
  maxDisplayCount,
}) {
  const [inputValue, setInputValue] = useState(String(displayCount));

  const handleInputChange = (e) => {
    // 입력값이 비어있는 경우
    if (e.target.value === "") {
      setInputValue("");
      return;
    }

    // 정규표현식을 사용하여 입력값이 정수형태인지 확인
    const isIntegerString = /^[0-9]+$/g.test(e.target.value);

    // 입력값이 정수형태라면, state를 업데이트
    if (isIntegerString) {
      // 입력값이 최대값보다 크다면, 최대값으로 설정
      if (Number(e.target.value) > maxDisplayCount) {
        setInputValue(String(maxDisplayCount));
      } else {
        setInputValue(e.target.value);
      }
    }
  };

  useEffect(() => {
    setInputValue(String(displayCount));
  }, [displayCount]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className="modal-overlay"
      ariaHideApp={false}
    >
      <div className="modal-wrapper">
        <h2 className="option-modal-title">옵션 설정</h2>

        <div className="option-section">
          <label className="option-label" htmlFor="displayCount">
            챔피언 수:
          </label>
          <input
            type="number"
            id="displayCount"
            value={inputValue}
            onChange={handleInputChange}
            className="option-input"
          />
        </div>

        <div className="option-section">
          <h3>정렬 옵션</h3>
          <select
            className="option-select"
            value={sortOption}
            onChange={(e) => setSortOption(e.target.value)}
          >
            <option value="default">없음</option>
            <option value="alphabetical">이름</option>
          </select>
        </div>

        <div className="option-buttons">
          <button
            className="option-save-button"
            onClick={() => {
              if (inputValue === "") {
                alert("챔피언 수를 입력해주세요.");
                return;
              } // 입력값이 비어있는 경우 저장하지 않음
              setDisplayCount(Number(inputValue));
              closeModal();
            }}
          >
            저장
          </button>
          <button onClick={closeModal} className="close-button">
            닫기
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default OptionModal;
